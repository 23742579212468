.about {
  background-image: url("./Images/BCKGRND.png");
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 50px 0;
}

.container {
  width: 80%;
  max-width: 1350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.about-me,
.education-section {
  width: 100%;
}

.education-section h2 {
  padding: 0;
}

.education-container {
  padding-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}

.education-entry {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin-top: 10px;
  width: calc(33% - 40px);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.school-logo {
  width: 80px;
  height: auto;
  margin-right: 10px;
}

.education-info {
  flex-grow: 1;
  text-align: right;
}

@media (max-width: 1205px) {
  .about {
    height: auto;
  }
  .education-container {
    flex-direction: column;
    align-items: center;
  }

  .education-entry {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .school-logo,
  .education-info {
    text-align: center;
    align-self: center;
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .education-info p {
    font-size: 14px;
  }
}
