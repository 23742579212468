.contact {
  background-image: url('./Images/BCKGRND.png');
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  padding: 50px 0;
}

.container {
  width: 80%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  padding: 0 20px;
  text-align: left; 
}

.email-button {
  background-color: #000868;
  color: #ffffff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  align-self: flex-start; 
  margin-top: 20px; 
}


@media (max-width: 1040px) {
  .contact {
    height: auto;
  }
  .container {
    width: 100%;
  }
}