.skills {
  background-image: url('./Images/BCKGRND.png');
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 0px 20px;
  box-sizing: border-box;
  color: #333;
}

.intro {
  max-width: 800px;
  text-align: center;
  margin-bottom: 16px;
}

.intro h2 {
  margin-bottom: 0px;
  font-size: 2.5rem;
  color: #222;
}

.intro p {
  font-size: 1rem;
  color: #666;
}

.skills-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

.skill-category {
  flex-basis: 300px;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.skill-category h2 {
  color: #000435;
  margin-bottom: 10px;
}

.skill-category p {
  font-size: 0.9rem;
  margin-bottom: 15px;
  color: #555;
}

.skill-category ul {
  list-style: none;
  padding: 0;
}

.skill-category ul li {
  background-color: #f8f9fa;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 5px;
  color: #333;
}