.contact2 {
  background-image: url('./Images/BCKGRND.png');
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: auto;
  padding: 50px 0;
}

.container2 {
  width: 80%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: flex-end; 
    padding: 0 20px;
    padding-bottom: 60px;
    text-align: right;
}

.email-button2 {
    background-color: #000868;
    color: #ffffff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    align-self: flex-end; 
    margin-top: 20px; 
 
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 5px 10px; 
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modal-content img {
  max-width: 100%;
  height: auto;
  max-height: 70vh;
  object-fit: cover;
}

.modal-content button {
  background-color: #000868;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  margin: 10px 5px;
  cursor: pointer;
}

@media (max-width: 1040px) {
  .contact2 {
    height: auto;
  }
  .container2 {
    width: 100%;
  }
}