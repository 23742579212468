.project {
  background-image: url('./Images/BCKGRND.png');
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 50px 0;
}

.projects-container {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-around; 
  width: 100%; 
  max-width: 1600px; 

}

.project-button {
  background-color: #000868;
  color: #ffffff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  align-self: center; 
  margin-top: 20px; 
}

.project-intro {
  max-width: 800px;
  text-align: center;
 
}

@media (max-width: 768px) {
  .projects-container {
    justify-content: center; 
  }
}