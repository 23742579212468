body, html, #root, .App {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  cursor:pointer;
}

h1, h2, a, button {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  
}

h2 {
  font-size: 32px;
  color: #000435;
  margin-bottom: 0;
}

p, li, label {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  cursor:default;
}

.overlay-text p {
  font-size: 25px;
}

main {
  position: relative;
  overflow-x: hidden; 
}

.section {
  padding: 20px;
  box-sizing: border-box;
} 

.stayBottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  background-color: #000868;
  color: white;
  text-align: center;
  border: none;
  cursor: pointer;
  font-family: "Nunito", sans-serif; /* Keeping the font family the same */
}

@media (min-width: 768px) {
  .stayBottom {
    bottom: 20px;
    left: 50%;
    width: auto;
    padding: 10px 20px;
    transform: translateX(-50%);
    border-radius: 50px;
  }
}

/* Add this media query for mobile devices to adjust the font size */
@media (max-width: 767px) {
  .stayBottom {
    font-size: 18px; /* Adjust this value to the desired font size on mobile */
    padding: 15px 20px;
  }
}


.particles-overlay-container {
  position: relative;
  height: 100vh;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFF;
  z-index: 10;
  text-align: center;
}

.overlay-text {
  width: 75%;
}

.overlay-text h1 {
  font-size: 55px;
}