.project-card {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background: #fff;
  margin: 20px auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  width: 66.67%; /* This was already set to take up 2/3, but ensure it's effectively applied */
  
}

.project-info {
  padding: 20px;
  flex: 1;
  text-align: left;
}

.project-image {
  flex: 1;
  overflow: hidden;
}

.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.project-technologies img {
  margin-right: 10px;
  width: 24px;
  height: auto;
}

.button-container {
  display: flex;
  justify-content: center; /* Align buttons to the start */
  flex-wrap: wrap; /* Allow wrapping if needed, but try to keep buttons side by side */
  gap: 10px; /* Space between buttons when they wrap */
}

.project-button, .code-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #000868;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 10px;
  min-width: 120px; /* Use min-width for flexibility */
  /* Remove fixed height to let content and padding define the size */
}

.code-button {
  background: #000435;
  margin-left: 0; /* Ensure there's no additional left margin throwing off alignment */
}

/* Adjustments for project card layout */
.project-info {
  display: flex;
  flex-direction: column;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .project-button, .code-button {
    width: auto; /* Allow buttons to fit content or adjust as necessary */
    margin: 10px 0; /* Stack buttons with space between them */
  }
  /* Ensure buttons are centered in their container */
  .project-info {
    align-items: center; /* This centers the buttons in the flex container */
  }
}

/* Responsiveness for mobile views */
@media (max-width: 950px) {
  .project-card {
    flex-direction: column;
  }

  .project-image {
    display: none;
  }
}

@media (max-width: 768px) {
  .project-card {
    width: 90%;
  }
}

